import React from "react";
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// third-party
import { IntlProvider } from 'react-intl';

// load locales files
const loadLocaleData = (locale) => {
    switch (locale) {
        case 'fr':
            return import('../utils/locales/fr.json');
        // case 'ro':
        //     return import('../utils/locales/ro.json');
        // case 'zh':
        //     return import('../utils/locales/zh.json');
        case 'tr':
            return import('../utils/locales/tr.json');
        case 'ua':
            return import('../utils/locales/ua.json');
        case 'ru':
            return import('../utils/locales/ru.json');
        case 'sk':
            return import('../utils/locales/sk.json');
        case 'cs':
            return import('../utils/locales/cs.json');
        case 'pl':
            return import('../utils/locales/pl.json');
        case 'es':
            return import('../utils/locales/es.json');
        default:
            return import('../utils/locales/en.json');
    }
};

// ==============================|| LOCALIZATION ||============================== //

const Locales = ({ children }) => {
    const customization = useSelector((state) => state.customization);
    const [messages, setMessages] = useState();

    useEffect(() => {

        let timeoutId; 
        let lang = navigator.language;
        let userLang;

        const getLang = localStorage.getItem("language");

        if (getLang) {
            loadLocaleData(getLang).then((d) => {
                setMessages(d.default);
            });
            userLang = getLang.substring(0, 2);
        } else { 
            loadLocaleData(lang.substring(0, 2)).then((d) => {
                setMessages(d.default);
            });
            userLang = lang.substring(0, 2);
        }

        timeoutId = setTimeout(() => {
            localStorage.setItem("language", userLang);
        }, 1000);

        return () => {
            clearTimeout(timeoutId); 
        };
    }, [customization.locale]);

    return (
        <>
            {messages && (
                <IntlProvider locale={customization.locale} defaultLocale="en" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </>
    );
};

Locales.propTypes = {
    children: PropTypes.node
};

export default Locales;
