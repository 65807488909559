import React from "react";
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Grid';
import { FormattedMessage } from 'react-intl';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  outline: "none",
  boxShadow: 24,
  p: 4,
};

const Notice = ({ notice, setNotice, noticeObj, readNotice }) => {

  const noticeTypes = [
    { type: "0", content: <Typography mt={2}> {noticeObj[0]?.content} </Typography> },
    { type: "1", content: <Grid mt={2}><iframe src={noticeObj[0]?.link} width="800" height="600"></iframe></Grid> },
    { type: "2", content: <Grid mt={2}> <img width="100%" height="600px" src={noticeObj[0]?.link} /> </Grid> },
    { type: "3", content: <Grid mt={2}> <iframe src={noticeObj[0]?.link} width="800" height="600"></iframe> </Grid> }
  ];

  const noticeContent = popupType => noticeTypes.find(i => i.type === popupType).content;
  
  return (
    <Modal open={notice} onClose={() => setNotice(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description" >
      <Box sx={style}>
        <Grid mt={2} align="center">
          {noticeObj[0]?.title.toLowerCase().includes("webinar")
            ?
              <>
                <Typography textAlign="center" fontSize="18px" color="#663BB9" marginBottom={1}> {noticeObj[0]?.title} </Typography>
                <a target="_blank" href="https://us06web.zoom.us/j/81690589990?pwd=bwMdniw8c35obAuZcPJKLMwotakwDQ.1">
                  <img src={noticeObj[0]?.link + "?" + localStorage.getItem("sas")} alt="meet_image"/>
                </a>
                {noticeContent(noticeObj[0]?.typePopup)}
              </>
            :
              <>
                <Typography textAlign="center" fontSize="18px" color="#663BB9"> {noticeObj[0]?.title} </Typography>
                 {noticeContent(noticeObj[0]?.typePopup)}
              </>
          }
         
        </Grid>
        <Box onClick={readNotice} display="flex" justifyContent="flex-end" mt={2}><Button variant="contained"><FormattedMessage id="Read" /></Button></Box>
      </Box>
    </Modal>
  );
};

export default Notice;
