import React, { useState, useEffect } from 'react'
import Badge from '@mui/material/Badge';
import Stack from '@mui/material/Stack';
import PropTypes from 'prop-types';
import * as actionTypes from '../../../store/actions';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, ButtonBase } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import SchoolIcon from '@mui/icons-material/School';
// project imports
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
import LocalizationSection from './LocalizationSection';
import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import NotificationSection from './NotificationSection';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';
import Notice from '../../../caller-components/Notice';
import newInfo from '../../../caller-components/NewInfo';
import { IconMenu2 } from '@tabler/icons';
import NewInfo from '../../../caller-components/NewInfo';
import axios from 'axios';



// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleLeftDrawerToggle }) => {

    const [isDark, setIsDark] = React.useState(false);
    const theme = useTheme();
    const customization = useSelector((state) => state.customization);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userData = window.location.href.includes('operation') ? null : JSON.parse(localStorage.getItem('userData'));
    const [newInfo, setNewInfo] = useState(false);
    const [notice,setNotice] = useState(false);
    const [noticeObj,setNoticeObj] = useState({})

    const paths = ['fullversiontwo', 'analysisversiontwo','doctordetail'];
    const isIframe = window.self !== window.top;
    const pathname = isIframe ? window.top.location.pathname : window.location.pathname;
    const isPathNotIncluded = !paths.some(path => pathname.includes(path));

    const handleDarkMode = () => {
        setIsDark(!isDark)
        !isDark ? dispatch({ type: actionTypes.MENU_TYPE, navType: "dark" }) : dispatch({ type: actionTypes.MENU_TYPE, navType: "light" })
    }

    const getNotice = async () => {
        try {
          const response = await axios.post(
            `/api2/v2/popup/`
          );
         setNoticeObj(response.data);
         if(localStorage.hasOwnProperty('userData')){
            if (JSON.parse(localStorage.getItem('userData')).country === response.data[0].country && response.data[1]) {
                setTimeout(() => setNotice(true),250)
            }
        }
        } catch (err) {
          console.log(err);
          // navigate('/error-page');
        }
      };

      const readNotice = async () => {
        try {
          const response = await axios.post(`/api2/v2/addPopup/`,{"id":noticeObj[0].id});
         console.log(response.data)
         setNotice(false);
        } catch (err) {
          console.log(err);
          // navigate('/error-page');
        }
      };
    
    useEffect(() => {
        if(isPathNotIncluded){
            getNotice();
        }
    }, [])
    return (
        <>
            {!window.location.href.includes('operation') &&
                <>
                <NewInfo newInfo={newInfo} setNewInfo={setNewInfo} />
                {notice && <Notice readNotice = {readNotice} noticeObj = {noticeObj} notice={notice} setNotice={setNotice} /> }
                    <Box
                        sx={{
                            width: 228,
                            display: 'flex',
                            [theme.breakpoints.down('md')]: {
                                width: 'auto'
                            }
                        }}
                    >
                        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                            <LogoSection />
                        </Box>
                        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
                            <Avatar
                                variant="rounded"
                                sx={{
                                    ...theme.typography.commonAvatar,
                                    ...theme.typography.mediumAvatar,
                                    transition: 'all .2s ease-in-out',
                                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.secondary.light,
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                    '&:hover': {
                                        background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.secondary.dark,
                                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.secondary.light
                                    }
                                }}
                                onClick={handleLeftDrawerToggle}
                                color="inherit"
                            >
                                <IconMenu2 stroke={1.5} size="1.3rem" />
                            </Avatar>
                        </ButtonBase>
                    </Box>

                    {/* header search */}
                    {/* <SearchSection /> */}
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                        {/* <button onClick={handleDarkMode}>dark mode</button> */}
                    </Box>
                    {/* live customization & localization */}
                    <Box mr={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
                        <LocalizationSection />
                    </Box>

                    {/* notification & profile */}
                    {/* <NotificationSection />
            <NotificationSection /> */}
                    <Box display="flex" gap="0.5rem" mr={2}>
                        <Stack spacing={2} direction="row">
                            <Badge onClick={() => setNewInfo(true)} style={{ cursor: 'pointer' }} color="secondary">
                                <SchoolIcon style={{ fontSize: "26px" }} color="action" />
                            </Badge>
                            {/* <Badge onClick={() => navigate('/notifications')} style={{ cursor: 'pointer' }} badgeContent={customization?.globalDataCounts?.notificationsCount} color="secondary">
                                <CircleNotificationsIcon style={{ fontSize: "26px" }} color="action" />
                            </Badge>
                            <Badge onClick={() => navigate('/messages')} style={{ cursor: 'pointer' }} badgeContent={customization?.globalDataCounts?.messagesCount} color="secondary">
                                <EmailIcon style={{ fontSize: "26px" }} color="action" />
                            </Badge> */}
                              <Badge onClick={() => navigate('/notifications')} style={{ cursor: 'pointer' }} badgeContent={JSON.parse(localStorage.getItem('userData'))?.len_of_notifications} color="secondary">
                                <CircleNotificationsIcon style={{ fontSize: "26px" }} color="action" />
                            </Badge>
                            <Badge onClick={() => navigate('/messages')} style={{ cursor: 'pointer' }} badgeContent={JSON.parse(localStorage.getItem('userData'))?.len_of_notes} color="secondary">
                                <EmailIcon style={{ fontSize: "26px" }} color="action" />
                            </Badge>
                        </Stack>
                    </Box>
                    <ProfileSection />

                    {/* mobile header */}
                    <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <MobileSection />
                    </Box>
                </>}
        </>
    );
};

Header.propTypes = {
    handleLeftDrawerToggle: PropTypes.func
};

export default Header;
